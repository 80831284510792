import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // },
  {
    path: "/",
    name: "menu_customer",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/reservation/index.vue"
      );
    },
  },
  {
    path: "/reservation",
    name: "reservation",
    component: function () {
      return import("../views/reservation/reservation.vue");
    },
  },
  {
    path: "/summary",
    name: "summary",
    component: function () {
      return import("../views/reservation/formSummary.vue");
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: function () {
      return import("../views/reservation/dialogPayment.vue");
    },
  },
  {
    path: "/checkin",
    name: "checkin",
    component: function () {
      return import("../views/checkin/index.vue");
    },
  },
  {
    path: "/display",
    name: "display",
    component: function () {
      return import("../views/display/index.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
